.list_title {
    font-size: 40px;
    text-align: left;
    color: white;
    padding-bottom: 8px;
}

.list_subtitle {
    font-size: 18px;
    text-align: left;
    color: white;
    padding-bottom: 16px;    
}

.list_text {
    width: 65%;
    color: white;
    padding-bottom: 16px;
    line-height: 25px; 
    text-align: justify; 
}

.project_items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem; 
    margin-top: 16px;
}

.project_items li {
    list-style: none;
    border: solid 1px white;
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;    
}

@-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
}

@media (max-width: 992px) {
    .project_items {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .list_title {
        font-size: 26px;  
    }

    .list_subtitle {
        font-size: 14px;  
    }

    .list_text {
        font-size: 14px;  
        width: 100%; 
    }

    .project_items {
        grid-template-columns: repeat(1, 1fr);
    }
}