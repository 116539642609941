.controls {
  display: flex;
  flex-direction: column; 
  align-items: center; 
}

.buttons {
  display: flex;
  gap: 10px;
}
  
.btn {
  width: 114px;
  margin: auto;
  margin-top: 10px;
  padding: 10px 16px;
  border: 1px solid #887392;
  background-color: #887392;
  border-radius: 26px;
  cursor: pointer;
  letter-spacing: 1px;
  font: inherit;
  color: #ffffff;
  transition: all .2s ease 0s;
}

.btn:hover,
.btn:active {
  background-color: #fa923f;
  border-color: #fa923f;
  transition: all .2s ease 0s;
}

.btn:disabled {
  background-color: #d3d3d3;
  border-color: #d3d3d3;
  cursor: not-allowed;
}

.controls p {
  color: #ffffff;
  margin-top: 15px;
}
  