.topic {
    color: lightsalmon;
    font-size: 40px;
    text-align: left;
    padding-top: 32px;
    padding-bottom: 8px;
}

.topic_p {
    font-size: 18px;
    text-align: left;
    color: white;
    padding-bottom: 16px;
}

.topics {
    max-width: 60rem;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    text-align: center;
    align-items: center;
    padding-bottom: 32px;
}

.topics li {
    list-style: none;
    margin: 30px 0;
}

.topics li img {
    max-width: 100%;
    height: auto;
    width: 70px; 
    height: 70px;    
}

.techName {
    color: white;
    font-size: 14px;
    margin-top: 8px;
}

@media (max-width: 768px) {
    .topic {
        font-size: 26px;  
    }

    .topic_p {
        font-size: 14px;  
    }

    .topics {
        grid-template-columns: repeat(3, 1fr);
    }
}