.item {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all .3s ease 0s;
}

.item_link {
    text-decoration: none;
}

.item:hover,
.item:active {
  background-color: #fa923f;
  color: white;
  box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.3), 0px 5px 15px rgba(0, 0, 0, 0.35);
  transform: scale(1.04);
  transition: all .3s ease 0s;
}

.item_image {
    width: 100%;
    height: 250px;
    overflow: hidden;    
}

.item_image img {
    width: 100%;
    transition: opacity 0.3s ease;    
}

.item_desc {
    text-align: center;
}

.item_desc h1 {
    margin: 10px 0;
    color: white;
}

.item_desc p {
    padding: 0 10px 10px 10px;
    color: white;
}