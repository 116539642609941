.about {
    width: 95%;
    max-width: 60rem;
    margin: 6px auto;
    line-height: 8px;
    background-color: #dfdbe6;
    border-radius: 6px;
    padding: 8px;
  }

.about p {
  color: #252734;
  font-size: 18px;
  line-height: 1.2;
  padding: 0.5rem;
  text-align: justify;
}

@media (max-width: 768px) {
  .about p {
    font-size: 14px;
  }  
}