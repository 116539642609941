.main_header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navigation {
  background-color: #252734;
  width: 100%;
}

.navbar {
  margin: auto;
  padding: 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 70rem;
}

.navbar_logo {
    color: #fff;
  }

.navbar_logo_link {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    font-size: 1.5rem;
}
  
  .navbar_list {
    list-style-type: none;
    text-align: center;
  }
  
  .navbar_item {
    display: inline-block;
    margin-right: 20px;
  }
  
  .navbar_link {
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .navbar_link:hover {
    background-color: #fa923f;
  }

  .active {
    background-color: #fa923f;
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }

  .mobile_navbar {
    height: 100%;
  }
  
  .mobile_navbar_list li {
    display: block;
    text-align: center;
    padding: 10px 0;
  }

  .mobileNav_btn_menu {
    width: 3rem;
    height: 3rem;
    background: transparent;
    border: none;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 2rem;
    cursor: pointer;
  }

  .mobileNav_btn_menu span {
    display: block;
    width: 3rem;
    height: 2.5px;
    background: white;
  }

  @media (max-width: 768px) {
    .navbar {
      justify-content: flex-start;
      padding: 10px 20px;
    }

    .mobileNav_btn_menu,
    .close_button {
      display: flex;
      height: 34px;
      margin-right: 20px;
    }

    .navbar_logo_link {
      font-size: 18px;
    }

    .navbar_list {
      display: none;
    }
  }