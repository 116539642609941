.mobile_nav {
    position: fixed;
    left: 0;
    top: 0px;
    z-index: 1002;
    height: 100vh;
    width: 320px;
    background: #252734;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding-top: 50px;
}

.close_button {
    background: none;
    border: none;
    font-size: 3.5rem;
    position: absolute;
    top: 2px;
    right: 10px;
    cursor: pointer;
    color: #fff;
}