.wrap {
  position: relative;
  background-image: url("../../assets/images//backg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 200px;
  z-index: 1;
}

.wrap::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 0;
  background: #333646;
} 

.wrap_sec {
  margin: 0 15px;
  position: relative; 
  z-index: 1; 
}

.wrap img {
  width: 100%;
}

.section {
    max-width: 30rem;    
    margin: 0 auto;
    background: #dfdbe6;
    display: block;
    padding-bottom: 40px;
    text-align: center;
    position: relative; 
    z-index: 1; 
  }

  .section_img {
    margin: auto;
    width: 200px;
    height: 200px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    top: -100px;
    margin-bottom: -70px;
  }

  .section_img img { 
    width: 100%;
    height: 100%;  
  }

  .section_about {
    padding-top: 40px;
  }

  .section_about h1 {
    font-size: 28px;
  }

  .section_about h1,
  .section_about p {
    text-align: center;
    margin: 16px 0;
  }

  span {
    color: lightsalmon;
  }

  .section_about p {
    color: #252734;
    font-size: 20px;
  }

.btn {
    width: 222px;
    margin: auto;
    margin-top: 40px;
    padding: 16px 24px;
    background-color: #887392;
    border-radius: 26px;
    font-weight: bold;
    cursor: pointer;
    letter-spacing: 2px;
    transition: all .2s ease 0s;
}

.btn:hover {
    background-color: #fa923f;
    transition: all .2s ease 0s;
}

.btn a {
    text-decoration: none;
    color: #ffffff;
}

  @media (max-width: 768px) {
    .section {
      max-width: 25rem;
    } 

    .section_about {
        padding-left: 0; 
        padding-top: 1rem; 
    }

    .section_about h1 {
      font-size: 24px;
    }

    .section_about p {
      font-size: 18px;
    }

    .btn a {
      font-size: 12px;
    }
  }